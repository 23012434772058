.rbc-time-header {
  margin-right: 0!important;
}

.rbc-time-view {
  border-radius: 8px!important;
  border: 1.5px solid #959595!important;
}

.rbc-header {
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #1E1E1E;

  font-family: 'Noto Sans JP'!important;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.176px;
  border-bottom: none!important;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.rbc-header + .rbc-header {
  border-left: 1.5px solid #959595!important;
}
.rbc-time-content {
  border-top: 1.5px solid #959595!important;
}

.rbc-event {
  border-radius: 8px;
  height: 48px!important;
  margin-top: 4px;

  font-family: 'Noto Sans JP'!important;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.176px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
}

.rbc-timeslot-group {
  border-bottom: 1.5px solid #959595!important;
  min-height: 56px!important;
  max-height: 56px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    min-height: 68px!important;
    max-height: 68px!important;
  }
}

.rbc-time-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1E1E1E;

  font-family: 'Noto Sans JP'!important;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  width: 87px;
  letter-spacing: -0.176px;
}

.rbc-day-slot .rbc-events-container {
  margin: 0px 8px!important;
  padding: 0 8px !important;
  border-right: 0!important;
  border-left: 0!important;
}

.rbc-time-content > * + * > * {
  border-left: 1.5px solid #959595!important;
}

.rbc-time-header.rbc-overflowing {
  border-right: none!important;
}

.rbc-time-header-content {
  border-left: 1.5px solid #959595!important;
}

.rbc-allday-cell {
  display: none;
}

.add-interview-times-container {
  .BaseTable__row {
    // height: 60px !important;
    // border-bottom: 1px solid #eeeeee;
    .BaseTable__row-cell-text {
      height: 100%;
    }
  }
  display: block;
  margin-left: 220px;
  width: calc(100% - 220px);

  margin-top: 180px;
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
    // position: fixed;
    // height: -webkit-fill-available;
    overflow: auto;
    margin-top: 75px;
  }
  .add-interview-times-inner {
    // display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    @media (max-width: 375px) {
      width: 350px;
    }
    .main-info-container {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;
      @media (max-width: 768px) {
        padding: 0 8px;
      }

      .slots-container {
        border-radius: 8px;
        border: 2px solid #33A8ED;
        color: #08105D;
        text-align: center;
        font-family: 'Noto Sans JP'!important;
        font-weight: 700;
        line-height: 150%;

        padding: 10px;

        @media (max-width: 768px) {
          padding: 4px 10px;
        }

        .slots-value {
          font-size: 24px;
          letter-spacing: -0.264px;
          @media (max-width: 768px) {
            font-size: 20px;
            letter-spacing: -0.22px;
          }
        }

        .slots-subvalue {
          font-size: 16px;
          letter-spacing: -0.176px;
          text-wrap: nowrap;
          @media (max-width: 768px) {
            font-size: 12px;
            letter-spacing: -0.132px;
          }
        }
      }

      .main-info-text {
        color: #1E1E1E;
        font-family: 'Noto Sans JP'!important;
        font-size: 24px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.264px;
        text-align: left;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 171%;
          letter-spacing: -0.154px;
        }
        .blue {
          color: #0D99FF;
        }

        .icon {

        }

        br {
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
    .sub-details {
      margin: 0px auto 20px;
      color: #007AFF;
      font-size: 18px;

      width: 100%;
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
    .details-container {
      display: flex;
      margin-bottom: 0px;
      width: 100%;
      justify-content: center;
      @media (max-width: 768px) {
        // width: 350px;
        justify-content: space-between;
      }
      .today-date {
        width: 100px;
        .today-text {
          background-color: gray;
          color: #FFFFFF;
          border-radius: 4px;
        }
        .month-text {

        }
        .day-text {

        }
        .date-text {
          font-size: 30px;
        }
      }
      .current-job-name {
        .text {
          color: #FFFFFF;
          background-color: gray;
          padding: 10px 20px;
          border-radius: 5px;
        }
        width: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          width: 230px;
          font-size: 13px;
        }
      }
    }
    .mobile-add-section {
      display: none;
      @media (max-width: 768px) {
        // width: 350px !important;
        width: 100%;
        padding: 0 16px;
        display: flex !important;
        height: calc(100vh - 200px);
        .rbc-calendar {
          // width: 350px !important;
          width: 100%;
        }
        .rbc-event {
          padding: 0px 8px !important;
          z-index: 10 !important;
          &:hover {
            cursor: pointer;
            z-index: 9999 !important;
            &:before {
            }
          }
        }
        .rbc-timeslot-group {
          // height: 20px !important;
          // max-height: 20px !important;
          .rbc-time-slot {
            border-top: 0px;
            border-bottom: 0px!important;
          }
        }
        .rbc-timeslot-group-hover {
          z-index: 9;
          &:hover {
            cursor: pointer;
            background: orange;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px!important;
            width: 100%;
            &:before {
              content: "面接枠を入れる";
            }
          }
        }
        .rbc-event-label {
          display: none;
        }
      }
    }
    ._loading_overlay_wrapper {
      width: 100%;
      justify-content: center;
      display: flex;
    }
    .add-section {
      width: 1100px;
      // height: 500px;
      max-width: 100%;
      height: 73vh;
      @media (max-width: 768px) {
        display: none;
      }
      .rbc-event {
        z-index: 10 !important;
        &:hover {
          cursor: pointer;
          z-index: 9999 !important;
          &:before {
          }
        }
      }
      .rbc-timeslot-group {
        // height: 20px !important;
        .rbc-time-slot {
          border-top: 0px;
        }
      }
      .rbc-timeslot-group-hover {
        z-index: 9;
        &:hover {
          cursor: pointer;
          background: orange;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          height: 48px!important;
          margin: 4px 4px;
          width: 100%;
          @media (max-width: 768px) {
            margin: 10px 4px;
          }
          justify-content: center;
          &:before {
            content: "面接枠を入れる";
          }
        }
      }
      .rbc-event-label {
        display: none;
      }

    }
  }
}
