.info-modal-add-slot-calendar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
}
@media (max-width: 768px) {
  .info-modal-add-slot-calendar {
    left: 0;
    padding-top: 100px;
  }
}
.info-modal-add-slot-calendar .modal-outer-container {
  width: 340px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.info-modal-add-slot-calendar .modal-outer-container .info-image-1 {
  background-color: #FFFFFF;
  border-radius: 15px;
  width: 100%;
}
.info-modal-add-slot-calendar .modal-outer-container .btn {
  background-color: #FFFFFF;
  font-size: 17px;
  border-radius: 35px;
  width: 600px;
  border: 1px solid #007Aff;
  color: #007AFF;
  margin: 40px auto 0px 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.info-modal-add-slot-calendar .modal-outer-container .close-icon {
  position: absolute;
  top: 1.5%;
  left: 95%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  border-width: 0;
}
.info-modal-add-slot-calendar .modal-outer-container .modal-inner-container {
  width: 350px;
  top: 50px;
  margin: 10px auto;
  border-radius: 15px;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .info-modal-add-slot-calendar .modal-outer-container .modal-inner-container {
    width: 350px;
  }
}
.info-modal-add-slot-calendar .modal-outer-container .modal-inner-container .header {
  font-size: 25px;
  margin: 10px auto;
  padding-top: 20px;
  color: #007AFF;
}
.info-modal-add-slot-calendar .modal-outer-container .modal-inner-container .sub-header {
  text-align: center;
  font-size: 11px;
  margin: 0px auto;
  padding-top: 20px;
  color: #007AFF;
}